.custom-bg {
  position: relative;
  background-color: black;
  z-index: 0; 

}
.custom-bg::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('./assets/me.png');
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: auto 80%;
  margin-right: 3%;
  opacity: 0.3;
  min-height: 100vh;
  z-index: -1;
}
