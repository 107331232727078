
.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    color: #f7fafc;
  }
  
  .chat-container {
    max-width: 79dvw;
    height: 60vh;
    margin: 25px auto;
    margin-bottom: 5px;
    border-radius: 10px;
    padding: 20px;
    background-color: #51545e3c;
    color: #f7fafc;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  
  
  }
  
  .chat-container::-webkit-scrollbar {
    width: 5px;
  }
  
  .chat-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3p x#d1cd4f;
    border-radius: 5px;
  }
  
  .chat-container::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px #d1cd4f;
  }
  
  .chat-input {
    background-color: #51545e3c;
    border: none;
    color: #f7fafc;
    padding: 10px;
    border-radius: 5px;
    width: calc(100% - 40px);
  }
  .chat-in {
    display: flex;
    margin: 10px auto;
    max-width: 79dvw;
  }

  .chat-gen {
    margin: 10px auto;
    /* max-width: 40dvw; */
    max-width: 600px;
    max-height:inherit; 

  }
  
  .send-btn {
    background-color: #d1cd4f;
    border: none;
    color: #f7fafc;
    padding: 10px;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  
  .loading {
    display:inline-block;
    clip-path: inset(0 3ch 0 0);
    animation: l 1s steps(4) infinite;
  }
  
  @keyframes l {
    to {
      clip-path: inset(0 -1ch 0 0)
    }
  }
  .disclaimer {
    color: #a0aec0;
    font-size: 0.8rem;
    text-align: center;
    margin-top: 20px;
    display: flex;
    bottom: 0;
  }
  
  .chat-box {
    background-color: #51545e3c;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    max-width: 70%;
  
  }
  
  .message {
    width: 100%;
    display: flex;
    /* background-color: #d1cd4f; */
  
  }
  .message-temp {
  
    background-color: #51545e3c;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    width: fit-content;
    /* max-width: 85%; */
    /* display: flex; */
    /* flex-direction: row; */
  
  }
  .bot-message {
    align-self: flex-start;
    text-align: left;
    background-color: #d1cd4f3c;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    width: fit-content;
  }
  
  .user-message {
    background-color: #51545e3c;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    width: fit-content;
    align-self: flex-end;
    text-align: right;
    
  
  }
  
